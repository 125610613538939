import React from "react";
import EventCard from "./EventCard";
import { Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import utils from "../../components/utils";


export default function MainPage(props) {
    document.title = "Karaoke in Movimento"
    const navigate = useNavigate()

    const [eventList, setEventList] = React.useState()
    const [isLoading, setIsLoading] = React.useState(true);

    const handleClick = () => {
        navigate("/login")
    }

    React.useEffect(() => {
        setIsLoading(true)
        const today = new moment()
        utils.apiGet('events/list?date='+today.format('yyyy-MM-DD'))
            .then(r => {
                setEventList(r.content)
                setIsLoading(false)
            }).catch(err => {
                setIsLoading(false)
            })
    }, [])

    if (isLoading)
        return <></>
    return <div className="mainPage">
        <div className="scroll-card">
        <div className="title">
            <a href="http://www.movimento.events"><img class="logo-movimento" src="/imgs/Logo-Karaoke.png"/></a>
            <Typography fontFamily="Karla" variant="h3" fontSize={30}>Prossimi <span>EVENTI</span></Typography>
        </div>
        <div className="cardContainer">
            {eventList?.map((i, k) => <div style={{ padding: "0.2rem" }} key={k}><EventCard {...i} />
            </div>)}
        </div>
        </div>
        <div className="karaoke">
            <Typography fontFamily="Karla" variant="h1" fontSize={40}><span>KARAOKE & DJ</span><br />in Movimento</Typography>
            <Button className="button-pink" variant="contained" size="large" onClick={handleClick}>INIZIAMO!</Button>
            <Typography fontFamily="Karla" fontWeight={100} fontSize={20}>Premi per cantare al karaoke</Typography>
            <a style={{textDecoration: "none"}} href="https://www.movimento.events/"><Button className="button-pink-small" variant="contained" size="large">VISITA IL SITO</Button></a>
            <Typography fontFamily="Karla" fontSize={12}>P.IVA 04569060983</Typography>
            <Typography fontFamily="Karla"><a style={{textDecoration: "none"}} href="https://stefifoxapps.it/">Made by StefifoxApps</a></Typography>
        </div>

    </div>

}